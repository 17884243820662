@import "_colors.scss";

.k-dialog {
  background-color: $color-bg-popups;
  border-radius: 16px;

  .k-dialog-actions {
    border: none;
    padding: 0 1rem 1rem 1rem;
  }
}

.k-window {
  background-color: $color-bg-popups;

  &.welcome-window {
    .k-window-titlebar {
      display: none;
    }
  }
}
