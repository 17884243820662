$color-white: #FFFFFF;
$color-primary: #01337D;
$color-primary-darker: #002159;
$color-primary-lighter: #03449E;
$color-border-highlight: #0967D2;
$color-border-primary: #B9B9B9;
$color-border-secondary: #CBD2D9;
$color-text-primary: #212529;
$color-text-secondary: #616E7C;
$color-text-disabled: #9AA5B1;
$color-text-success: #05606E;
$color-text-error: #CF1124;
$color-bg-highlight: #F5FBFF;
$color-bg-disabled: #F5F7FA;
$color-bg-popups: #FAFDFF;
