$typography-font-family: Inter;
$typography-heading-1-font-size: 36px;
$typography-heading-1-line-height: 40px;
$typography-heading-2-font-size: 30px;
$typography-heading-2-line-height: 36px;
$typography-text-20-font-size: 20px;
$typography-text-20-line-height: 26px;
$typography-text-16-font-size: 16px;
$typography-text-16-line-height: 20px;
$typography-text-13-font-size: 13px;
$typography-text-13-line-height: 18px;
$typography-text-11-font-size: 11px;
$typography-text-11-line-height: 16px;

@mixin typography {
  font-family: Inter;
}

@mixin typography-heading-1-medium {
  @include typography;
  font-size: $typography-heading-1-font-size;
  line-height: $typography-heading-1-line-height;
  font-weight: 500;
}

@mixin typography-heading-2-medium {
  @include typography;
  font-size: $typography-heading-2-font-size;
  line-height: $typography-heading-2-line-height;
  font-weight: 500;
}

@mixin typography-text-20 {
  @include typography;
  font-size: $typography-text-20-font-size;
  line-height: $typography-text-20-line-height;
  font-weight: 400;
}

@mixin typography-text-16 {
  @include typography;
  font-size: $typography-text-16-font-size;
  line-height: $typography-text-16-line-height;
  font-weight: 400;
}

@mixin typography-text-16-bold {
  @include typography;
  font-size: $typography-text-16-font-size;
  line-height: $typography-text-16-line-height;
  font-weight: 600;
}

@mixin typography-text-13 {
  @include typography;
  font-size: $typography-text-13-font-size;
  line-height: $typography-text-13-line-height;
  font-weight: 400;
}

@mixin typography-text-11 {
  @include typography;
  font-size: $typography-text-11-font-size;
  line-height: $typography-text-11-line-height;
  font-weight: 400;
}

.heading-1 {
  @include typography-heading-1-medium;
}

.heading-2 {
  @include typography-heading-2-medium;
}

.text-20 {
  @include typography-text-20;
}

.text-16 {
  @include typography-text-16;
}

.text-16-bold {
  @include typography-text-16-bold;
}

.text-13 {
  @include typography-text-13;
}

.text-11 {
  @include typography-text-11;
}

.text-primary {
  color: $color-text-primary;

  svg {
    path[stroke]:not([stroke="none"]) {
      stroke: $color-text-primary;
    }

    path[fill]:not([fill="none"]) {
      fill: $color-text-primary;
    }
  }
}

.text-secondary {
  color: $color-text-secondary;

  svg {
    path[stroke]:not([stroke="none"]) {
      stroke: $color-text-secondary;
    }

    path[fill]:not([fill="none"]) {
      fill: $color-text-secondary;
    }
  }
}

.text-danger {
  color: $color-text-error;

  svg {
    path[stroke]:not([stroke="none"]) {
      stroke: $color-text-error;
    }

    path[fill]:not([fill="none"]) {
      fill: $color-text-error;
    }
  }
}

.text-disabled {
  color: $color-text-disabled;

  svg {
    path[stroke]:not([stroke="none"]) {
      stroke: $color-text-disabled;
    }

    path[fill]:not([fill="none"]) {
      fill: $color-text-disabled;
    }
  }
}
