@import '~@progress/kendo-theme-bootstrap/dist/all.scss';
@import '_colors.scss';
@import '_typography.scss';
@import '_dialogs.scss';
@import '_menus.scss';

.k-button-solid-primary {
  border-color: $color-primary;
  color: $color-white;
  background-color: $color-primary;

  &:hover,
  &.k-hover {
    border-color: $color-primary-lighter;
    color: $color-white;
    background-color: $color-primary-lighter;
  }
}

.k-button-outline-primary {
  color: $color-primary;

  &:hover,
  &.k-hover {
    border-color: $color-primary;
    color: $color-white;
    background-color: $color-primary;
  }
}

.k-loader-primary {
  color: $color-primary;
}

a {
  color: $color-primary;

  &:hover {
    color: $color-primary-lighter;
  }
}

.k-slider {
  .k-slider-selection {
    background-color: $color-primary;
  }

  .k-draghandle {
    border-color: $color-primary;
    background-color: $color-primary;
  }

  .k-draghandle:hover {
    border-color: $color-primary-lighter;
    background-color: $color-primary-lighter;
  }
}

.k-input-solid {

  &:focus,
  &.k-focus {
    border-color: $color-border-highlight;
    color: $color-text-primary;
    background-color: $color-white;
    box-shadow: 0 0 0 0.25rem rgba(9, 103, 210, 0.25);
  }

  &.k-invalid,
  &.ng-invalid.ng-touched,
  &.ng-dirty {
    border-color: $color-text-error;
  }
}


.k-appbar {
  .k-menu {
    .k-menu-link {
      padding: 0;

      .k-menu-expand-arrow {
        display: none;
      }
    }
  }
}

.primary-app-bar {
  height: 64px;
  border-bottom: 1px solid $color-border-primary;
  background: $color-white;
  box-shadow: none;
}

.secondary-app-bar {
  height: 64px;
  border-bottom: 1px solid $color-border-secondary;
  background-color: $color-white;
  box-shadow: none;
  padding: 0 8px;

  .actions-appbar-section {
    .k-button {
      flex-direction: column;
      margin: 0.25rem 1rem;
      padding-block: 0.25rem;
      gap: 0.25rem;

      .k-icon {
        width: 24px;
        height: 24px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .k-button-text {
        @include typography-text-13;
      }
    }
  }

  .nav-appbar-section {
    .k-button {
      padding: 20px 10px;
      min-width: 110px;
      background: $color-white;
      border-radius: 0;
      border: none;

      .k-button-text {
        line-height: 24px;
      }

      &:hover {
        background: $color-bg-highlight;
      }

      &:focus,
      &.k-focus {
        box-shadow: none;
      }
    }

    .active .k-button {
      color: $color-primary;
      font-weight: 700;
      background: $color-bg-highlight;
      -webkit-box-shadow: inset 0px -2.5px $color-primary;
      -moz-box-shadow: inset 0px -2.5px $color-primary;
      box-shadow:inset 0px -2.5px $color-primary;

      svg {
        filter: brightness(0) saturate(100%) invert(13%) sepia(66%) saturate(3217%) hue-rotate(209deg) brightness(93%) contrast(102%);
      }
    }

    .disabled .k-button {
      background-color: $color-bg-disabled;
      color: $color-text-disabled;
      cursor: not-allowed;

      svg {
        filter: brightness(0) saturate(100%) invert(72%) sepia(9%) saturate(420%) hue-rotate(171deg) brightness(91%) contrast(84%);
      }
    }
  }
}

.logo {
  font-size: 16px;
  line-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
}

.presets {
  .k-chip-list {
    .k-chip {
      padding: 8px 16px;
      color: $color-text-primary;
      border-radius: 8px;
      background: transparent;
      font-size: 16px;
      margin-right: 3rem;
      border-color: transparent;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background: $color-bg-popups;
      }

      &:focus,
      &.k-focus {
        font-weight: 600;
        border-color: transparent;
        background: $color-bg-popups;
        box-shadow: none;
      }
    }
  }
}
