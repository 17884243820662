@import "_colors.scss";

.k-menu-group .k-item:focus>.k-link,
.k-menu-group .k-item.k-focus>.k-link,
.k-menu.k-context-menu .k-item:focus>.k-link,
.k-menu.k-context-menu .k-item.k-focus>.k-link {
  box-shadow: none;
}

.k-menu-group .k-item>.k-link:active,
.k-menu-group .k-item>.k-link.k-active,
.k-menu-group .k-item>.k-link.k-selected,
.k-menu.k-context-menu .k-item>.k-link:active,
.k-menu.k-context-menu .k-item>.k-link.k-active,
.k-menu.k-context-menu .k-item>.k-link.k-selected {
  color: $color-white;
  background-color: $color-primary-lighter;

  &:has(.text-danger) {
    background-color: $color-text-error;
  }

  .text-danger {
    color: $color-white !important;
  }
}

.k-menu-group .k-menu-link {
  padding-block: 0.5rem;
}
